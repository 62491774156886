import React from "react";
import "./Pages.css";
import chat from "../../assets/chat.png";

const Anzeige = () => {
  return (
    <div className="anzeige">
      <img width={300} src={chat} alt="Anzeige" />
      <p>
      <font style = {{color:"blue"}}>
        Sehr geehrte Patienten, <pre/>
        <div class="block">
        <font style = {{color:"red"}}>
        die Praxis ist am 26.09.2024 und 27.09.2024 nur für Rezepte, Terminvergaben etc. zu folgenden Zeiten geöffnet: <pre/>
        Donnerstag, den 26.09.2024   08:00-14:00<pre/>
        Freitag, den 27.09.2024        08:00-12:00<pre/>
        Bei dringenden Notfällen wenden Sie sich bitte an das nächstgelegene Krankenhaus!<pre/>
        </font></div>
        <pre/>
        </font>
        
        
        Wir sind eine Facharztpraxis, da kann es schon durchaus zu etwas längeren
        Wartezeiten kommen. Dafür bitten wir um Verständnis.<pre/>
        <pre/>
        Die Untersuchung bei Neupatienten ist immer etwas aufwändiger. Bitte planen
        Sie genügend Zeit ein.<pre/>
        <pre/>
        Wir haben mehrere Untersuchungs-/Behandlungsräume, deswegen kann es
        auch durchaus sein, dass ein Patient, der nach Ihnen kam, vor Ihnen aufgerufen
        wird.<pre/>
        <pre/>
        Viele Patienten kommen z.B. nur zur Blutabnahme, Katheterwechsel usw. und
        müssen an diesem Tag nicht zum Arzt.<pre/>
        Das sind Untersuchungen, die von den Fachangestellten durchgeführt
        werden.<pre/>
        <pre/>
        An manchen Tagen haben wir auch Notfälle. Dies sind Patienten mit z.B.
        Koliken und unter Schmerzen können diese Patienten nicht warten und
        brauchen zeitnah eine Versorgung.<pre/>
        <pre/>
        Wir bitten um Ihr Verständnis<pre/>
        Ihr URO-Praxisteam<pre /><pre />
      </p>
    </div>
  );
};

export default Anzeige;
